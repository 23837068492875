import React from "react"
import { BlogHero } from "../../components/blogHero/blogHero"
import { MainLayout } from "../../components/mainLayout/mainLayout"
import { PostLink } from "../../components/postLink/post-link"
import { SEO } from "../../components/seo/seo"
import { graphql } from "gatsby"
import "./style.scss"

export default function Template({ data }) {
    const { posts, postsR } = data
    const { frontmatter, html } = posts

    const PostsR = postsR.edges.map((edge) => (
        <PostLink
            img={
                edge.node.frontmatter.featuredImage.childImageSharp
                    .gatsbyImageData
            }
            key={edge.node.id}
            post={edge.node}
        />
    ))
    return (
        <MainLayout>
            <SEO title="Blog" />
            <BlogHero />
            <div className="blog-post-container contained">
                <div className="blog-post">
                    <title>{frontmatter.title}</title>
                    <div className="author">
                        <div>{frontmatter.author}</div>
                        <div>{frontmatter.date}</div>
                    </div>

                    <div
                        className="blog-post-content"
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                    <div className={"tagContainer"}>
                        {frontmatter.tags.map((tag, i) => {
                            return <div key={i}>{tag}</div>
                        })}
                    </div>
                    {postsR.edges.length > 0 && (
                        <div className={"tagContainer"}>
                            <h1>Posts relacionados</h1>
                        </div>
                    )}
                    {PostsR != null && (
                        <div className={"tagContainer"}>{PostsR}</div>
                    )}
                </div>
            </div>
        </MainLayout>
    )
}

export const pageQuery = graphql`
    query ($slug: String!, $tags: [String]!) {
        posts: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                slug
                tags
                title
                author
                featuredImage {
                    childImageSharp {
                        gatsbyImageData(
                            width: 800
                            layout: CONSTRAINED
                            formats: [WEBP]
                        )
                    }
                }
            }
        }
        postsR: allMarkdownRemark(
            filter: {
                frontmatter: { tags: { in: $tags }, slug: { ne: $slug } }
            }
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        author
                        slug
                        tags
                        title
                        featuredImage {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 800
                                    layout: CONSTRAINED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`
